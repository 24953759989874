.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height as needed */
  pointer-events: all;
  position: fixed;
  inset: 0;
  height: 100vh;
  z-index: 1061;

  .blurredBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // backdrop-filter: blur(1px); /* Same blur amount as loader-container */
    background: rgba(
      255,
      255,
      255,
      0.5
    ); /* Same background color and opacity as loader-container */
    z-index: 1061; /* Place behind the loader */
  }

  .loader {
    width: 50px; /* Adjust the size of the loader */
    height: 50px; /* Make it a square */
    border: 8px solid transparent;
    border-top: 8px solid #38d8a2; /* First color */
    border-bottom: 8px solid #f99d3c; /* Second color */
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
    z-index: 1061;
  }
}

.onlyLoaderContainer {
  z-index: 1061;

  .onlyLoader {
    width: 50px; /* Adjust the size of the loader */
    height: 50px; /* Make it a square */
    border: 8px solid transparent;
    border-top: 8px solid #38d8a2; /* First color */
    border-bottom: 8px solid #f99d3c; /* Second color */
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
    z-index: 1061;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
